/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // var Textarea = $('#Form-Message').outerHeight();
        var WidthWindow = $(window).width();
        // var HeightWindow = $(window).height();
        // if (WidthWindow > 800) {
        //   $('#Url').attr('href',"/cotizador");
        // } else {
        //   $('#Url').attr('href',"/cotizador-mobile");
        // }
        var number = 1;
        var flag = false;
        var flag2 = false;
        /////////////////////SLIDERS
        $('#Tech-Grid').slick({
          dots: false,
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1000,
          pauseOnHover: true,
          infinite: true,
          responsive:[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                centerMode: true,
              }
            }
          ]
        });
        // console.log("Work...");
        // $('.wpcf7-form-control-wrap.Message').css({"height":Textarea});
        // $('#menu-item-147').click(function(e){
        //   e.preventDefault();
        // });
        // Menu
        $('#Menu-Mobile').click(function() {
          $('.Header-Home-Top-Menu').toggleClass('active');
          $('.Header-Other-Top-Menu').toggleClass('active');
        });
        $('#menu-menu li').on('click', function(e){
          if ($(this).find('.sub-menu').length) {
            e.preventDefault();
            $(this).siblings().removeClass('active');
            $(this).toggleClass('active');
          }
        });
        $('.sub-menu li').on('click', function(e){
          e.stopPropagation();
        });
        // $('.Services>h2').waypoint(function(dir){
        //   if(dir === 'down'){
        //     $('.Services>h2').addClass('animated fadeInDown');
        //   }else{
        //     $('.Services>h2').removeClass('animated fadeInDown');
        //   }
        // },{offset:'80%'});
        if ($(window).width() > 500) {
        ///////////////////// EFECTOS HOME SERVICES
        $('.Services>h2').waypoint(function(){
          $('.Services>h2').addClass('animated fadeInDown');
        },{offset:'80%'});
        $('.Services>p').waypoint(function(){
          $('.Services>p').addClass('animated fadeInDown');
        },{offset:'80%'});
        $('.Services-Soft>img').waypoint(function(){
          $('.Services-Soft>img').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('.Services-Soft>h3').waypoint(function(){
          $('.Services-Soft>h3').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('.Services-Soft>p').waypoint(function(){
          $('.Services-Soft>p').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('.Services-Team>img').waypoint(function(){
          $('.Services-Team>img').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('.Services-Team>h3').waypoint(function(){
          $('.Services-Team>h3').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('.Services-Team>p').waypoint(function(){
          $('.Services-Team>p').addClass('animated bounceInLeft');
        },{offset:'80%'});
        /////////////////////EFECTO HOME WORKS
        $('.Work-Grid>h2').waypoint(function(){
          $('.Work h2').addClass('animated fadeInDown');
        },{offset:'80%'});
        $('.Work-Grid-Box').waypoint(function(){
          $('.Work-Grid-Box').addClass('animated lightSpeedIn');
        },{offset:'80%'});
        /////////////////////EFECTO HOME TECHNOLOGIES
        $('.Tech>h2').waypoint(function(){
          $('.Tech>h2').addClass('animated fadeInDown');
        },{offset:'80%'});
        $('.Tech>p').waypoint(function(){
          $('.Tech>p').addClass('animated fadeInDown');
        },{offset:'80%'});
        $('.Tech-Grid-Box').waypoint(function(){
          $('.Tech-Grid-Box').addClass('animated zoomIn');
        },{offset:'80%'});
        /////////////////////EFECTO HOME FORM
        $('.Form').waypoint(function(){
          $('.Form').addClass('animated zoomIn');
        },{offset:'80%'});
        /////////////////////EFECT SERVICE
        $('.Service-Intro>img').waypoint(function(){
          $('.Service-Intro>img').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('#Service-Img1').waypoint(function(){
          $('#Service-Img1').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('#Service-Img2').waypoint(function(){
          $('#Service-Img2').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('#Service-Img3').waypoint(function(){
          $('#Service-Img3').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('#Service-Img4').waypoint(function(){
          $('#Service-Img4').addClass('animated bounceInLeft');
        },{offset:'80%'});
        /////////////////////
        $('.Service2-Intro>img').waypoint(function(){
          $('.Service2-Intro>img').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('#Service2-Img1').waypoint(function(){
          $('#Service2-Img1').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('#Service2-Img2').waypoint(function(){
          $('#Service2-Img2').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('#Service2-Img3').waypoint(function(){
          $('#Service2-Img3').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('#Service2-Img4').waypoint(function(){
          $('#Service2-Img4').addClass('animated bounceInLeft');
        },{offset:'80%'});
        /////////////////////EFECT WHO
        $('#Who-Img1').waypoint(function(){
          $('#Who-Img1').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('#Who-Img2').waypoint(function(){
          $('#Who-Img2').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('#Who-Img3').waypoint(function(){
          $('#Who-Img3').addClass('animated bounceInRight');
        },{offset:'80%'});
        $('#Who-Img4').waypoint(function(){
          $('#Who-Img4').addClass('animated bounceInLeft');
        },{offset:'80%'});
        $('#Who-Img3').waypoint(function(){
          $('#Who-Img5').addClass('animated bounceInRight');
        },{offset:'80%'});
        ///////////////////// EFECT TO SINGLE POST
        $('.Header-Home-Down').waypoint(function(dir){
          if(dir === 'down'){
            $('#Share-Init').addClass('animated bounceInRight');
          }else{
            $('#Share-Init').removeClass('animated bounceInRight');
          }
        },{offset:'20%'});
        $('#Share-Init').on('click',function(){
          $('.Post-Content-Share-Borde').toggleClass('animated bounceOutUp bounceInDown');
        });

        }//Fin If
        // $('#Work-Grid').slick({
        //   dots: false,
        //   arrows: false,
        //   slidesToShow: 1,
        //   slidesToScroll: 1,
        //   autoplay: true,
        //   pauseOnHover: true,
        //   infinite: true,
        //   responsive:[
        //     {
        //       breakpoint: 500,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
        // });
		/////////////////////Questionario Desktop
	// $( "input" ).on( "click", function() {
	// 	Id = $("input:checked")[0].parentElement.id;
	// 	$('.opciones').removeClass('marcado');
	// 	$("#"+Id).toggleClass('marcado');
  //   });
  $(".opciones").on("click", function(e){
    Id = e.target.id;
    $('.opciones').removeClass('marcado');
    $("#"+Id+" input").prop('checked', true);
    $("#"+Id).toggleClass('marcado');
  });
	///////////////////////////////////////////////
	$('#phone').keyup(function (){
		this.value = (this.value + '').replace(/[^0-9()+]/g, '');
	});
	///////////////////////////////////////////////
	function sig(ID) {
		var IdSig = ID + 1;
    var IdAnt = ID - 1;
    if (WidthWindow > 1050){
      $('#'+ID).removeClass('animated fadeInLeft fadeInRight fadeOutLeft fadeOutRight active');
      $('#'+IdSig).removeClass('animated fadeInLeft fadeInRight fadeOutLeft fadeOutRight active');
      $('#'+ID).toggleClass('animated fadeOutLeft');
      $('#'+IdSig).toggleClass('animated fadeInRight active');
      $('#'+IdAnt).removeClass('animated fadeInLeft fadeInRight fadeOutLeft fadeOutRight active');
    }else{
      $('#'+ID).removeClass('animated fadeInUp fadeOutUp fadeInDown fadeOutDown active');
      $('#'+IdSig).removeClass('animated fadeInUp fadeOutUp fadeInDown fadeOutDown active');
      $('#'+ID).toggleClass('animated fadeOutUp');
      $('#'+IdSig).toggleClass('animated fadeInUp active');
      $('#'+IdAnt).removeClass('animated fadeInUp fadeOutUp active');
      setTimeout(function(){
        $('#'+ID).removeClass('animated fadeInUp fadeOutUp active');
        $('#'+IdSig).removeClass('animated fadeInUp fadeOutUp');
      }, 1000);
    }
	}
	///////////////////////////////////////////////
	$('.anterior').on('click',function (event){
    var IdActual = parseInt(event.target.parentElement.id);
		var IdSig = IdActual + 1;
		var IdAnt = IdActual - 1;
    if (WidthWindow > 1050){
      $('#'+IdActual).removeClass('animated fadeInLeft fadeInRight fadeOutLeft fadeOutRight');
      $('#'+IdAnt).removeClass('animated fadeInLeft fadeInRight fadeOutLeft fadeOutRight active');
      $('#'+IdActual).toggleClass('animated fadeInRight fadeOutRight active');
      $('#'+IdAnt).toggleClass('animated fadeInLeft active');
      $('#'+IdSig).removeClass('animated fadeInLeft fadeInRight fadeOutLeft fadeOutRight active');
    }else{
      $('#'+IdActual).removeClass('animated fadeInUp fadeOutUp fadeInDown fadeOutDown');
      $('#'+IdAnt).removeClass('animated fadeInUp fadeOutUp fadeInDown fadeOutDown active');
      $('#'+IdActual).toggleClass('animated fadeOutDown active');
      $('#'+IdAnt).toggleClass('animated fadeInDown active');
      $('#'+IdSig).removeClass('animated fadeInDown fadeOutDown active');
      setTimeout(function(){
        $('#'+IdAnt).removeClass('animated fadeInDown fadeOutDown ');
        $('#'+IdActual).removeClass('animated fadeInDown fadeOutDown active');
      }, 1000);
    }
	});
	///////////////////////////////////////////////
	function validarTextarea(Q1,Q2,ID) {
		var valor1 = Q1.trim();
		var valor2 = Q2.trim();

		if (valor1 === '' || valor2 === '' ) {
			alert('the fields are empty');
		} else if (valor1.length < 2 || valor2.length < 2) {
			alert('the answers are very short');
		} else {
			sig(ID);
		}
	}
	///////////////////////////////////////////////
	function validarInput(name,company,phone,mail,count,ID) {
		var valor1 = name;
		var valor2 = company;
		var valor3 = phone;
		var valor4 = mail.trim();
		var valor5 = count;
		var regex = /[\w-\.]{2,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;

		if(valor1 === '' || valor2 === '' || valor3 === '' || valor3 === '' || valor3 === '' ) {
			alert('the fields are empty');
		} else if(valor1.length < 2 || valor2.length < 4 || valor3.length < 4 || valor4.length < 4 || valor5.length < 4) {
			alert('the answers are very short');
		} else if(!regex.test(valor4)){
			alert('I do not post a valid email');
		} else {
			sig(ID);
		}
	}
  ///////////////////////////////////////////////
	function validarInputMobile(name,company,phone,mail,ID) {
		var valor1 = name;
		var valor2 = company;
		var valor3 = phone;
		var valor4 = mail.trim();
		var regex = /[\w-\.]{2,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;

		if(valor1 === '' || valor2 === '' || valor3 === '' || valor3 === '' || valor3 === '' ) {
			alert('the fields are empty');
		} else if(!regex.test(valor4)){
			alert('I do not post a valid email');
		} else {
			sig(ID);
		}
	}
  ////////////////////////////////////////////
	////////////////////////////////////////////
  ////////////////////////////////////////////
        //Variables Respuestas =
	var q1,q2,q3,q4,q5,q61,q62,q71,q72,q73,q74,q75 = "";
    $('.hoja button').on('click',function(event){
      var IdActual = parseInt(event.target.parentElement.parentElement.parentElement.id);
      if (WidthWindow > 1050){
        switch (IdActual) {
          case 0:
            sig(IdActual);
            break;
          case 1:
            q1 = event.target.innerText;
            sig(IdActual);
            break;
          case 2:
            q2 = event.target.innerText;
            sig(IdActual);
            break;
          case 3:
            q3 = event.target.innerText;
            sig(IdActual);
            break;
          case 4:
            q4 = event.target.innerText;
            sig(IdActual);
            break;
          case 5:
            q5 = event.target.innerText;
            sig(IdActual);
            break;
          case 6:
            q61 = $('#nombrep').val();
            q62 = $('#spec').val();
            validarTextarea(q61,q62,IdActual);
            break;
          case 7:
            q71 = $('#name').val();
            q72 = $('#empresa').val();
            q73 = $('#phone').val();
            q74 = $('#mail').val();
            var radioButTrat = document.getElementsByName("opcion");
            for (var i=0; i<radioButTrat.length; i++) {
              if (radioButTrat[i].checked === true) {
                q75 = radioButTrat[i].value;
              }
            }
            validarInput(q71,q72,q73,q74,q75,IdActual);
            break;
        }
      }else{
        switch (IdActual) {
          case 0:
            sig(IdActual);
            break;
          case 1:
            q1 = event.target.innerText;
            sig(IdActual);
            break;
          case 2:
            q2 = event.target.innerText;
            sig(IdActual);
            break;
          case 3:
            q3 = event.target.innerText;
            sig(IdActual);
            break;
          case 4:
            q4 = event.target.innerText;
            sig(IdActual);
            break;
          case 5:
            q5 = event.target.innerText;
            sig(IdActual);
            break;
          case 6:
            q61 = $('#nombrep').val();
            q62 = $('#spec').val();
            validarTextarea(q61,q62,IdActual);
            break;
          case 7:
            q71 = $('#name').val();
            q72 = $('#empresa').val();
            q73 = $('#phone').val();
            q74 = $('#mail').val();

            validarInputMobile(q71,q72,q73,q74,IdActual);
            break;
          case 8:
            radioButTrat = document.getElementsByName("opcion");
            for ( i=0; i<radioButTrat.length; i++) {
              if (radioButTrat[i].checked === true) {
                q75 = radioButTrat[i].value;
              }
            }
            validarInput(q71,q72,q73,q74,q75,IdActual);
            break;
        }
      }
		});
    $("#end").click(function () {
      console.log(q1,q2,q3,q4,q5,q61,q62,q71,q72,q73,q74,q75);
      $("#end").hide();
			$.ajax({
				url: '../php/sendmail.php',
				type: 'post',
				data:{
					typeApp : q1,
					Benefict : q2,
					Puser : q3,
					Admin : q4,
					Pstate : q5,
					ProjectName : q61,
					SpecTechni : q62,
					Name : q71,
					Business : q72,
					Phone : q73,
					Mail : q74,
					SizeComp : q75,
				},
				success: function (response) {
					console.log('work2',response);
					console.log(response);
					if (res === 'pass'){
						// window.location.replace("https://southamericansoftware.com");
					}else{
            alert('There was a mistake');
					}
				}
			});
    });
		/////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
        /////////////////////
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Example
    'Example': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
